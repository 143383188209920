import { css } from 'astroturf';
import React, { Component } from 'react';

import Testimonials from '../../components/black-friday/Testimonials';
import PageHeader from '../../components/PageHeader';
import SEO from '../../components/SEO';
import Layout from '../../layout/index-noheader';

import BlackFridayHeader from '../../components/black-friday/header';
import CTA from '../../components/CTA';

const styles = css`
  .pricingtable {
    color: #f8f8fb;
    text-align: center;

    & > h2 {
      margin: 2rem auto 0;
      font-weight: 900;
      font-size: clamp(18px, 16px + 4vw, 48px);
      max-width: 30ch;
      color: #fff;
      filter: drop-shadow(0px 3px 0px #000);
    }

    & > small {
      max-width: 32rem;
      margin: 1rem auto 0;
      display: inline-block;
      padding-left: 2rem;
      padding-right: 2rem;
      font-size: 0.9rem;
      line-height: 1.5rem;
      font-weight: 400;
      color: #67b26f;

      & svg {
        vertical-align: text-top;
        margin-right: 0.25rem;
      }
    }
  }
  .punchout {
    background: linear-gradient(135deg, #2c82ad, ease-in-out, #67b26f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .faq {
    max-width: 60rem;
    text-align: left;
    padding: 0 2rem;
    margin: 4rem auto 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 3rem;

    & div {
      flex: 1 1 320px;
    }

    & h2 {
      flex: 1 0 100%;
      border-bottom: 3px solid;
      color: #4ca2cd;
    }

    & h3 {
      color: #58b993;
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }

    & p {
    }
  }
  .primaryButton {
    position: relative;
    pointer-events: all;
    margin: 8px 8px 4rem;
    font-size: calc(14px + 0.5vw);
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    background: linear-gradient(135deg, #2c82ad, #67b26f);
    border-radius: 40px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    line-height: 26px;
    transition: all 0.25s ease-in-out;
    padding: 0;

    & i {
      position: absolute;
      top: calc(100% + 10px);
      line-height: 1;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 12px;
      font-weight: normal;
      color: #fff;
      display: block;
      font-style: normal;
      opacity: 0.75;
    }

    & span {
      position: relative;
      z-index: 1;
      padding: 0.33em 1rem;
      content: ' ';
      display: block;
      background: transparent;
      width: calc(100%);
      height: calc(100%);
      z-index: 0;
      opacity: 1;
      transition: background 0.25s ease-in-out;
      border-radius: 40px;
    }

    & svg {
      vertical-align: middle;
      margin-top: -3px;
      margin-left: 10px;
    }

    @media (min-width: 600px) {
      & span {
        font-size: 1.125rem;
        padding: 0.5em 1rem;
      }
    }

    &:hocus {
      text-decoration: none;
      & span {
        background: #0a1530;
      }
    }
  }
  .testimonials {
    max-width: 60rem;
    margin: 2rem auto 0;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    position: relative;
    padding: 1.5rem;
    border-radius: 0.5rem;
  }
  .smalltestimonial {
    max-width: 17rem;
    flex: 1 1 20rem;
    display: flex;
    gap: 1rem;
    align-content: center;
    flex-direction: column;

    & div.image {
      flex: 0 0 60px;
      text-align: center;
      & img {
        border-radius: 100%;
        border: 3px solid rgb(88, 185, 147);
      }
    }

    & div.quote {
      font-size: 0.875rem;
      text-align: center;

      & q,
      & span {
        display: block;
      }

      & span {
        color: rgb(88, 185, 147);
        margin-top: 1rem;
        line-height: 1;
      }
    }
  }
`;

class Page extends Component {
  constructor() {
    super();

    this.state = {
      priceYearly: true,
    };
  }

  togglePricing = (value) => this.setState({ priceYearly: value });

  render() {
    return (
      <Layout background="rgb(53, 53, 70)">
        <SEO
          title="Polypane Black Friday 2023"
          description="Get the browser for ambitious web developers with a 30% discount."
          keywords={['']}
          pathname={this.props.location.pathname}
          ogFileName="blackfriday"
          ogCustomPath="/og-custom/"
        />
        <PageHeader nobg overflow>
          <BlackFridayHeader />
          <h1 style={{ color: '#fff', filter: 'drop-shadow(0px 3px 0px #000)' }}>
            ✨ Polypane <span className={styles.punchout}>Black Friday</span> Deal ✨
          </h1>
          <h2 style={{ color: '#fff', fontWeight: 800, filter: 'drop-shadow(0px 3px 0px #000)', fontSize: 'clamp(16px, 16px + 2vw, 36px)' }}>The Black Friday deal is no longer available.</h2>
        </PageHeader>

        <div className={styles.pricingtable}>
          <Testimonials />
          {/* <h2>
            Get Polypane for yourself
            <br /> <span className={styles.punchout}>or your entire team</span>
          </h2>
          <p style={{ color: '#fff', marginBottom: '2rem', maxWidth: "30rem", margin: "0 auto" }}>
            Build better websites in less time with a stand-alone browser that makes your site more responsive, more
            accessible and faster. Save hours on each project, love every minute of it.
          </p>
          <Table priceYearly={this.state.priceYearly} />
          <small style={{zIndex: 1, position: 'relative' }}>
            <Lock width={16} height={16} /> Secure payment with credit card or PayPal through Paddle. VAT may be
            deducted after filling in company details.
          </small>
          <div style={{ height: '2rem' }} />
          <ExpenseInfoBox />
          <div className={[styles.faq].join(' ')}>
            <h2>Frequently asked questions</h2>

            <div>
              <h3>When does Black Friday at Polypane end?</h3>
              <p>This year our Black Friday promotion lasts until the end of the day on Wednesday 30th.</p>
            </div>
            <div>
              <h3>How long does the discount last?</h3>
              <p>All discounts are for the lifetime of a subscription.</p>
            </div>
            <div>
              <h3>How many devices can I install Polypane on?</h3>
              <p>All Polypane subscriptions let you install Polypane on three devices per user.</p>
            </div>
            <div>
              <h3>Do I need an account before getting a subscription?</h3>
              <p>
                If you don't yet have an account, we will automatically make one for you with a temporary password so
                you can get started right away.
              </p>
            </div>
            <div>
              <h3>Does Polypane work with ...?</h3>
              <p>
                Polypane works with all major CMS (like WordPress, Django, Laravel), front-end frameworks (like React,
                Vue, Angular, Svelte, jQuery) and CSS Frameworks (like Bootstrap, Tailwind CSS, Bulma).
              </p>
            </div>
            <div>
              <h3>I want to get a license for more than 10 users.</h3>
              <p>
                To get licenses for largers teams{' '}
                <Link to="/support/" style={{ color: '#75a3ce' }}>
                  Contact us
                </Link>
                !
              </p>
            </div>
          </div> */}
          <CTA darkbg />
        </div>
      </Layout>
    );
  }
}

export default Page;
